
/* eslint-disable linebreak-style */
/* eslint-disable react/jsx-indent */

import React, { lazy } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import NewPassword from "../views/NewPassword";
// import { getMyProfile } from '../redux/user/user.actions.js';
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));

const Starter = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));
const Login = lazy(() => import("../components/auth/Login"));
const NotFound = lazy(() => import("../components/NotFound/index"));
const Category = lazy(() => import("../components/Category/index"));
const Admin = lazy(() => import("../components/Admin/index"));
const ChatList = lazy(() => import("../components/ChatList/index"));
const Media = lazy(() => import("../components/Media/index"));
const CompanyType = lazy(() => import("../components/CompanyType/index"));
const Eye = lazy(() => import("../components/Eye/index"));
const Hair = lazy(() => import("../components/Hair/index"));
const Language = lazy(() => import("../components/Language/index"));
const Origin = lazy(() => import("../components/Origin/index"));
const Notification = lazy(()=>import("../components/Notification"))
// const UserDetail = lazy(() => import("../components/UserDetail/index"));

const ProtectedRoute = ({
  redirectPath = '/login',
  children,
}) => {
  if (!localStorage.getItem('token')) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
};

const AllRoutes = (props) => {

  return (
    <Routes>
      <Route path="/login" element={<Login {...props} />} exact />
      <Route
        path="/reset-password/:resettoken"
        element={<NewPassword {...props} />}
        exact
      />
      <Route path="/" element={<ProtectedRoute><FullLayout /></ProtectedRoute>}>
        <Route path="/" element={
          <Navigate to="/login" />
        } exact />       
        <Route path="/:data" element={
          <ProtectedRoute>
            <Starter />
          </ProtectedRoute>
        } exact />        
        <Route path="about" element={
          <ProtectedRoute>
            <About />
          </ProtectedRoute>
        } exact />
        <Route path="alerts" element={
          <ProtectedRoute>
            <Alerts />
          </ProtectedRoute>
        } exact />
        <Route path="badges" element={
          <ProtectedRoute>
            <Badges />
          </ProtectedRoute>
        } exact />
        <Route path="buttons" element={
          <ProtectedRoute>
            <Buttons />
          </ProtectedRoute>
        } exact />
        <Route path="cards" element={
          <ProtectedRoute>
            <Cards />
          </ProtectedRoute>
        } exact />
        <Route path="grid" element={
          <ProtectedRoute>
            <Grid />
          </ProtectedRoute>
        } exact />
        <Route path="table" element={
          <ProtectedRoute>
            <Tables />
          </ProtectedRoute>
        } exact />
        <Route path="forms" element={
          <ProtectedRoute>
            <Forms />
          </ProtectedRoute>
        } exact />
        <Route path="breadcrumbs" element={
          <ProtectedRoute>
            <Breadcrumbs />
          </ProtectedRoute>
        } exact />
        <Route path="category" element={
          <ProtectedRoute>
            <Category />
          </ProtectedRoute>
        } exact />
        <Route path="admin" element={
          <ProtectedRoute>
            <Admin />
          </ProtectedRoute>
        } exact />
        <Route path="chatlist" element={
          <ProtectedRoute>
            <ChatList />
          </ProtectedRoute>
        } exact />
        <Route path="media" element={
          <ProtectedRoute>
            <Media />
          </ProtectedRoute>
        } exact />
        <Route path="company-type" element={
          <ProtectedRoute>
            <CompanyType />
          </ProtectedRoute>
        } exact />
        <Route path="eye" element={
          <ProtectedRoute>
            <Eye />
          </ProtectedRoute>
        } exact />
        <Route path="hair" element={
          <ProtectedRoute>
            <Hair />
          </ProtectedRoute>
        } exact />
        <Route path="language" element={
          <ProtectedRoute>
            <Language />
          </ProtectedRoute>
        } exact />
        <Route path="origin" element={
          <ProtectedRoute>
            <Origin />
          </ProtectedRoute>
        } exact />
          <Route path="notification" element={
          <ProtectedRoute>
            <Notification />
          </ProtectedRoute>
        } exact />
      </Route>
      {/* <Route path="/userDetails/:id" element={<ProtectedRoute> <FullLayout /> </ProtectedRoute>} >
        <Route path="" element={
          <UserDetail />
        } exact />
      </Route> */}
      <Route path="*" element={<NotFound />} />

      {/* Reading Training Complete */}

      {/* <Navigate path="/" to="/login" exact />
      <Navigate to="/" /> */}
    </Routes>
  )
};

export default AllRoutes
