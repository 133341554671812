import { combineReducers } from 'redux';
import * as practitionerReducer from "./practitioner/practitioner.reducer";
import * as userReducers from './user/user.reducers';
import * as categoryReducers from './category/caegory.reducers';
import * as companyTypeReducers from './companyType/companyType.reducers';
import * as eyeReducers from './eye/eye.reducers';
import * as hairReducers from './hair/hair.reducers';
import * as languageReducers from './language/language.reducers';
import * as originReducers from './origin/origin.reducers';
import * as mediaReducers from './media/media.reducers';
import * as notificationReducers from './notificatin/Notification.reducer'
import * as chatReducers from './chatList/chat.reducers'
import * as adminReducers from './admin/admin.reducers'
export const rootReducer = combineReducers({
    [practitionerReducer.practitionerFeatureKey]: practitionerReducer.reducer,
    [userReducers.userFeatureKey]: userReducers.reducer,
    [categoryReducers.categoryFeatureKey]: categoryReducers.reducer,
    [companyTypeReducers.categoryFeatureKey]: companyTypeReducers.reducer,
    [eyeReducers.eyeFeatureKey]: eyeReducers.reducer,
    [hairReducers.hairFeatureKey]: hairReducers.reducer,
    [languageReducers.languageFeatureKey]: languageReducers.reducer,
    [originReducers.originFeatureKey]: originReducers.reducer,
    [mediaReducers.mediaFeatureKey]: mediaReducers.reducer,
    [notificationReducers.NotificationFeatureKey]: notificationReducers.reducer,
    [adminReducers.adminFeatureKey]: adminReducers.reducer,
    [chatReducers.categoryFeatureKey]: chatReducers.reducer,
});