import * as chatActions from './chat.actions';

export const categoryFeatureKey = "chatInfo";

const initialState = {
    loading: false,
    errorMsg: "",
    chatList: []
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        // get bank details
        case chatActions.GET_CHAT_LIST:
            return {
                ...state,
                loading: true
            }
        case chatActions.GET_CHAT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                chatList: payload.data
            }
        case chatActions.GET_CHAT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "CLEAR_CATEGORY":
            return {
                ...state,
                loading: false,
                chatList: []
            }
        default: return state;
    }
};