import axios from "axios";
import { toast } from "react-toastify";

export const GET_HAIR_LIST = 'GET_HAIR_LIST';
export const GET_HAIR_LIST_SUCCESS = 'GET_HAIR_LIST_SUCCESS';
export const GET_HAIR_LIST_FAILURE = 'GET_HAIR_LIST_FAILURE';

export const ADD_HAIR = 'ADD_HAIR';
export const ADD_HAIR_SUCCESS = 'ADD_HAIR_SUCCESS';
export const ADD_HAIR_FAILURE = 'ADD_HAIR_FAILURE';

export const UPDATE_HAIR = 'UPDATE_HAIR';
export const UPDATE_HAIR_SUCCESS = 'UPDATE_HAIR_SUCCESS';
export const UPDATE_HAIR_FAILURE = 'UPDATE_HAIR_FAILURE';

export const DELETE_HAIR = 'DELETE_HAIR';
export const DELETE_HAIR_SUCCESS = 'DELETE_HAIR_SUCCESS';
export const DELETE_HAIR_FAILURE = 'DELETE_HAIR_FAILURE';

export const getEyeList = (token , search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_HAIR_LIST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/hair/v1?search=${search}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_HAIR_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: GET_HAIR_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const addEye = (data, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADD_HAIR });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/hair/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: ADD_HAIR_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: ADD_HAIR_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const editEye = (data, selectedId, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_HAIR });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/hair/v1?id=${selectedId}`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: UPDATE_HAIR_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: UPDATE_HAIR_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteEye = (ID, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_HAIR });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/hair/v1?id=${ID}`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: DELETE_HAIR_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: DELETE_HAIR_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const clearHair = () => {
    return async (dispatch) => {
        dispatch({ type: "CLEAR_HAIR" });
    }
};