import axios from "axios";
import { toast } from "react-toastify";

export const SET_NEW_PASSWORD_REQUEST = 'SET_NEW_PASSWORD_REQUEST';
export const SET_NEW_PASSWORD_SUCCESS = 'SET_NEW_PASSWORD_SUCCESS';
export const SET_NEW_PASSWORD_FAILURE = 'SET_NEW_PASSWORD_FAILURE';

export const setNewPassword = (resettoken, password, navigate) => {
    return async (dispatch) => {
        try {
            dispatch({ type: SET_NEW_PASSWORD_REQUEST });
            const url = `${process.env.REACT_APP_BASE_URL}/set/password/v1/${resettoken}`;
            const response = await axios.post(url, { password });
            dispatch({ type: SET_NEW_PASSWORD_SUCCESS, payload: response.data });
            navigate("/login");
            toast("Password Reset Successfully!");
        } catch (error) {
            console.error(error);
            dispatch({ type: SET_NEW_PASSWORD_FAILURE, payload: error.message });
            toast(error?.response?.data?.message);
        }
    };
};
