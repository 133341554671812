import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const GET_CATEGORY_LIST = 'GET_CATEGORY_LIST';
export const GET_CATEGORY_LIST_SUCCESS = 'GET_CATEGORY_LIST_SUCCESS';
export const GET_CATEGORY_LIST_FAILURE = 'GET_CATEGORY_LIST_FAILURE';

export const ADD_CATEGORY = 'ADD_CATEGORY';
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS';
export const ADD_CATEGORY_FAILURE = 'ADD_CATEGORY_FAILURE';

export const UPDATE_CATEGORY = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const getCategoryList = (token ,searchData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_CATEGORY_LIST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/category/v1?search=${searchData}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_CATEGORY_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_CATEGORY_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const addCategory = (data, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADD_CATEGORY });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/category/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: ADD_CATEGORY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_CATEGORY_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const editCategory = (data, selectedId, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_CATEGORY });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/category/v1?id=${selectedId}`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: UPDATE_CATEGORY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_CATEGORY_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteCategory = (ID, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_CATEGORY });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/category/v1?id=${ID}`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: DELETE_CATEGORY_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_CATEGORY_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const clearCategoryList = () => {
    return async (dispatch) => {
        dispatch({ type: "CLEAR_CATEGORY" });
    }
};