import * as userActions from './user.actions';

export const userFeatureKey = 'userInfo';

const initialState = {
    loading: false,
    isUserDetailChange: false,
    errorMsg: "",
    loggedUser: {},
    users: [],
    reportedList: [],
    paymentList: [],
    userDetails: {},
    isChangeUserStatus: false
};

export const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        // login user
        case userActions.LOGIN_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.LOGIN_USER_SUCCESS:
            localStorage.setItem('token', payload.token);
            return {
                ...state,
                loading: false,
                loggedUser: payload.data
            }

        case userActions.LOGIN_USER_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // user detail
        case userActions.ADMIN_USER_DETAIL:
            return {
                ...state,
                loading: true
            }

        case userActions.ADMIN_USER_DETAIL_SUCCESS:
            return {
                ...state,
                loading: false,
                userDetail: payload.data
            }

        case userActions.ADMIN_USER_DETAIL_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // forgot password
        case userActions.ADMIN_FORGOT_PASSWORD:
            return {
                ...state,
                loading: true
            }

        case userActions.ADMIN_FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case userActions.ADMIN_FORGOT_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // login user
        case userActions.ADMIN_RESET_PASSWORD:
            return {
                ...state,
                loading: true
            }

        case userActions.ADMIN_RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false
            }

        case userActions.ADMIN_RESET_PASSWORD_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }
        // get my profile
        case userActions.GET_MY_PROFILE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.GET_MY_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                loggedUser: payload.data
            }

        case userActions.GET_MY_PROFILE_FAILURE:
            localStorage.removeItem('token');
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get payment data
        case userActions.GET_PAYMENT_LIST_REQUEST:
            return {
                ...state,
                loading: true
            }

        case userActions.GET_PAYMENT_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                paymentList: payload.data
            }

        case userActions.GET_PAYMENT_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        // get user list
        case userActions.GET_USER_LIST_REQUEST:
            return {
                ...state,
            }

        case userActions.GET_USER_LIST_SUCCESS:
            return {
                ...state,
                users: payload.data
            }

        case userActions.GET_USER_LIST_FAILURE:
            return {
                ...state,
                errorMsg: payload,
                users: []
            }



        // get reported list
        case userActions.GET_REPORTED_LIST_REQUEST:
            return {
                ...state,
            }

        case userActions.GET_REPORTED_LIST_SUCCESS:
            return {
                ...state,
                reportedList: payload.data
            }

        case userActions.GET_MY_PROFILE_FAILURE:
            return {
                ...state,
                errorMsg: payload
            }

        // get user info
        case userActions.GET_USER_PROFILE_REQUEST:
            return {
                ...state,
                loading: false
            }

        case userActions.GET_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                userDetails: payload.data
            }

        case userActions.GET_USER_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                userDetails: []
            }

        // update user info
        case userActions.CHANGE_PROFILE_REQUEST:
            return {
                ...state,
                loading: false,
                isUserDetailChange: false
            }

        case userActions.CHANGE_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUserDetailChange: true
            }

        case userActions.CHANGE_PROFILE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload,
                isUserDetailChange: false
            }

        // change user status
        case userActions.CHANGE_USER_STATUS_REQUEST:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: false
            }

        case userActions.CHANGE_USER_STATUS_SUCCESS:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: true
            }

        case userActions.CHANGE_USER_STATUS_FAILURE:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: false,
                errorMsg: payload
            }
        //Block User

        case userActions.BLOCK_USER_REQUEST:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: false
            }

        case userActions.BLOCK_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: true
            }

        case userActions.BLOCK_USER_FAILURE:
            return {
                ...state,
                loading: false,
                isChangeUserStatus: false,
                errorMsg: payload
            }
        // verify user
        case userActions.VERIFY_USER_DOC_REQUEST:
            return {
                ...state,
                loading: false
            }

        case userActions.VERIFY_USER_DOC_SUCCESS:
            return {
                ...state,
                loading: false,
            }

        case userActions.VERIFY_USER_DOC_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case "CLEAR_CHANGE_USER_STATUS":
            return {
                ...state,
                isChangeUserStatus: false
            }

        default: return state;
    }
};