import axios from "axios";
import { toast } from "react-toastify";

export const GET_ADMIN_LIST = 'GET_ADMIN_LIST';
export const GET_ADMIN_LIST_SUCCESS = 'GET_ADMIN_LIST_SUCCESS';
export const GET_ADMIN_LIST_FAILURE = 'GET_ADMIN_LIST_FAILURE';

export const ADD_ADMIN = 'ADD_ADMIN';
export const ADD_ADMIN_SUCCESS = 'ADD_ADMIN_SUCCESS';
export const ADD_ADMIN_FAILURE = 'ADD_ADMIN_FAILURE';

export const UPDATE_ADMIN = 'UPDATE_ADMIN';
export const UPDATE_ADMIN_SUCCESS = 'UPDATE_ADMIN_SUCCESS';
export const UPDATE_ADMIN_FAILURE = 'UPDATE_ADMIN_FAILURE';

export const DELETE_ADMIN = 'DELETE_ADMIN';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';

export const getAdminList = (token ,searchData) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ADMIN_LIST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/user/list/v1?type=admin&search=${searchData}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_ADMIN_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: GET_ADMIN_LIST_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const addAdmin = (data, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADD_ADMIN });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: ADD_ADMIN_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_ADMIN_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const editAdmin = (data, selectedId, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_ADMIN });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/${selectedId}/v1`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: UPDATE_ADMIN_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: UPDATE_ADMIN_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteAdmin = (ID, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_ADMIN });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/${ID}/v1`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: DELETE_ADMIN_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: DELETE_ADMIN_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const clearAdminList = () => {
    return async (dispatch) => {
        dispatch({ type: "CLEAR_ADMIN" });
    }
};