import axios from "axios";
import { toast } from "react-toastify";

export const GET_MEDIA_LIST = 'GET_MEDIA_LIST';
export const GET_MEDIA_LIST_SUCCESS = 'GET_MEDIA_LIST_SUCCESS';
export const GET_MEDIA_LIST_FAILURE = 'GET_MEDIA_LIST_FAILURE';

export const ADD_MEDIA = 'ADD_MEDIA';
export const ADD_MEDIA_SUCCESS = 'ADD_MEDIA_SUCCESS';
export const ADD_MEDIA_FAILURE = 'ADD_MEDIA_FAILURE';

export const UPDATE_MEDIA = 'UPDATE_MEDIA';
export const UPDATE_MEDIA_SUCCESS = 'UPDATE_MEDIA_SUCCESS';
export const UPDATE_MEDIA_FAILURE = 'UPDATE_MEDIA_FAILURE';

export const DELETE_MEDIA = 'DELETE_MEDIA';
export const DELETE_MEDIA_SUCCESS = 'DELETE_MEDIA_SUCCESS';
export const DELETE_MEDIA_FAILURE = 'DELETE_MEDIA_FAILURE';

export const clearMedia = () => {
    return async (dispatch) => {
        dispatch({ type: "CLEAR_MEDIA" });
    }
};

export const getMediaList = (token , search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_MEDIA_LIST });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/media/v1?search=${search}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_MEDIA_LIST_SUCCESS, payload: res.data });
        } catch (error) {
            dispatch({ type: GET_MEDIA_LIST_FAILURE, payload: error.message });
            toast(error.message);
        }
    }
};

export const addMedia = (data, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADD_MEDIA });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/media/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: ADD_MEDIA_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            dispatch({ type: ADD_MEDIA_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const editMedia = (data, selectedId, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_MEDIA });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/media/v1?id=${selectedId}`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: UPDATE_MEDIA_SUCCESS, payload: res.data });
        } catch (error) {
            dispatch({ type: UPDATE_MEDIA_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteMedia = (ID, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_MEDIA });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/media/v1?id=${ID}`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: DELETE_MEDIA_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: DELETE_MEDIA_FAILURE, payload: error.message });
            toast(error.message);
        }
    }
};
