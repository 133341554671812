import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const GET_ORIGIN = 'GET_ORIGIN';
export const GET_ORIGIN_SUCCESS = 'GET_ORIGIN_SUCCESS';
export const GET_ORIGIN_FAILURE = 'GET_ORIGIN_FAILURE';

export const ADD_ORIGIN = 'ADD_ORIGIN';
export const ADD_ORIGIN_SUCCESS = 'ADD_ORIGIN_SUCCESS';
export const ADD_ORIGIN_FAILURE = 'ADD_ORIGIN_FAILURE';

export const UPDATE_ORIGIN = 'UPDATE_ORIGIN';
export const UPDATE_ORIGIN_SUCCESS = 'UPDATE_ORIGIN_SUCCESS';
export const UPDATE_ORIGIN_FAILURE = 'UPDATE_ORIGIN_FAILURE';

export const DELETE_ORIGIN = 'DELETE_ORIGIN';
export const DELETE_ORIGIN_SUCCESS = 'DELETE_ORIGIN_SUCCESS';
export const DELETE_ORIGIN_FAILURE = 'DELETE_ORIGIN_FAILURE';

export const clearOrigin = () => {
    return async (dispatch) => {
        dispatch({ type: "CLEAR_ORIGIN" });
    }
};

export const getOriginList = (token , search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_ORIGIN });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/origin/v1?search=${search}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_ORIGIN_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: GET_ORIGIN_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const addOrigin = (data, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADD_ORIGIN });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/origin/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: ADD_ORIGIN_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: ADD_ORIGIN_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const editOrigin = (data, selectedId, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_ORIGIN });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/origin/v1?id=${selectedId}`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: UPDATE_ORIGIN_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: UPDATE_ORIGIN_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteOrigin = (ID, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_ORIGIN });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/origin/v1?id=${ID}`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: DELETE_ORIGIN_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: DELETE_ORIGIN_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};
