import * as languageActions from './language.actions';

export const languageFeatureKey = "languageInfo";

const initialState = {
    loading: false,
    isUpdated: false,
    isAdded: false,
    isDeleted: false,
    errorMsg: "",
    languageList: []
};

export const reducer = (state = initialState, action) => {
    const { payload, type } = action;
    switch (type) {
        case "CLEAR_LANGUAGE":
            return {
                ...state,
                loading: false,
                isUpdated: false,
                isAdded: false,
                isDeleted: false
            }
        // get bank details
        case languageActions.GET_LANGUAGE:
            return {
                ...state,
                loading: true
            }
        case languageActions.GET_LANGUAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                languageList: payload.data
            }
        case languageActions.GET_LANGUAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case languageActions.ADD_LANGUAGE:
            return {
                ...state,
                loading: true,
                isAdded: false
            }

        case languageActions.ADD_LANGUAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isAdded: true
            }

        case languageActions.ADD_LANGUAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        case languageActions.UPDATE_LANGUAGE:
            return {
                ...state,
                loading: true,
                isUpdated: false
            }

        case languageActions.UPDATE_LANGUAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true
            }

        case languageActions.UPDATE_LANGUAGE_FAILURE:
            return {
                ...state,
                loading: false,
                isUpdated: false,
                errorMsg: payload
            }

        case languageActions.DELETE_LANGUAGE:
            return {
                ...state,
                loading: true,
                isDeleted: false
            }

        case languageActions.DELETE_LANGUAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true
            }

        case languageActions.DELETE_LANGUAGE_FAILURE:
            return {
                ...state,
                loading: false,
                errorMsg: payload
            }

        default: return state;
    }
};