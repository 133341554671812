import axios from "axios";
import { toast } from "react-toastify";
const token = localStorage.getItem('token');

export const GET_LANGUAGE = 'GET_LANGUAGE';
export const GET_LANGUAGE_SUCCESS = 'GET_LANGUAGE_SUCCESS';
export const GET_LANGUAGE_FAILURE = 'GET_LANGUAGE_FAILURE';

export const ADD_LANGUAGE = 'ADD_LANGUAGE';
export const ADD_LANGUAGE_SUCCESS = 'ADD_LANGUAGE_SUCCESS';
export const ADD_LANGUAGE_FAILURE = 'ADD_LANGUAGE_FAILURE';

export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';
export const UPDATE_LANGUAGE_SUCCESS = 'UPDATE_LANGUAGE_SUCCESS';
export const UPDATE_LANGUAGE_FAILURE = 'UPDATE_LANGUAGE_FAILURE';

export const DELETE_LANGUAGE = 'DELETE_LANGUAGE';
export const DELETE_LANGUAGE_SUCCESS = 'DELETE_LANGUAGE_SUCCESS';
export const DELETE_LANGUAGE_FAILURE = 'DELETE_LANGUAGE_FAILURE';

export const clearLanguage = () => {
    return async (dispatch) => {
        dispatch({ type: "CLEAR_LANGUAGE" });
    }
};

export const getLanguageList = (token , search) => {
    return async (dispatch) => {
        try {
            dispatch({ type: GET_LANGUAGE });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/language/v1?search=${search}`;
            const res = await axios.get(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: GET_LANGUAGE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: GET_LANGUAGE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const addLanguage = (data, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: ADD_LANGUAGE });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/language/v1`;
            const res = await axios.post(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: ADD_LANGUAGE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: ADD_LANGUAGE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const editLanguage = (data, selectedId, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: UPDATE_LANGUAGE });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/language/v1?id=${selectedId}`;
            const res = await axios.put(url, data, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: UPDATE_LANGUAGE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: UPDATE_LANGUAGE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};

export const deleteLanguage = (ID, token) => {
    return async (dispatch) => {
        try {
            dispatch({ type: DELETE_LANGUAGE });
            const url = `${process.env.REACT_APP_BASE_URL}/admin/language/v1?id=${ID}`;
            const res = await axios.delete(url, {
                headers: {
                    Authorization: token
                }
            });
            dispatch({ type: DELETE_LANGUAGE_SUCCESS, payload: res.data });
        } catch (error) {
            console.error(error);
            console.log('error', error);
            dispatch({ type: DELETE_LANGUAGE_FAILURE, payload: error.message });
            toast(error.response.data.message);
        }
    }
};
